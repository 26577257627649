import { Component, inject, OnInit, signal } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-intermediate-loader',
  templateUrl: './intermediate-loader.component.html',
  styleUrls: ['./intermediate-loader.component.scss'],
})
export class IntermediateLoaderComponent implements OnInit {
  orgName = signal('');

  private dialogConfig = inject(DynamicDialogConfig);

  constructor() {}

  ngOnInit(): void {
    const { data } = this.dialogConfig;
    this.orgName.set(data.orgName);
  }
}

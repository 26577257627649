@if (orgs.length > 1) {
  <div class="switch-org-container-new">
    <div class="switch-org-container-nav">
      <div class="back-button-org-logo">
        <div class="tw-cursor-pointer left-icon-container" (click)="goBack()">
          <app-svg-sprite icon="arrow-tail-left" classes="tw-w-20-px tw-mr-16-px tw-fill-baltic-sea"></app-svg-sprite>
        </div>
        <a (click)="switchToOrg(activeOrg)">
          <img src="{{this.targetConfig.FULL_LOGO_URL_BLACK}}" alt="{{ targetConfig.BRAND_NAME }}" class="fyle-logo">
        </a>
      </div>
      <div class="sign-out-container" (click)="logout()">
        <app-svg-sprite icon="power" class="common-icon sign-out-icon"></app-svg-sprite>
        <span class="tw-text-free-speech-red"><strong>Sign out</strong></span>
      </div>
    </div>
    <app-active-org-card
      [isLoading]="isLoading"
      [activeOrg]="activeOrg"
      [isPrimary]="activeOrg?.id === primaryOrg?.id"
      (activeOrgClick)="onActiveOrgClick()"
      />
    <div class="organization-list">
      <div class="org-header-search-box tw-flex-col">
        <div class="tw-flex tw-items-center tw-justify-between tw-mb-spacing-8">
          <div [ngClass]="{'hide-header' : showSearchBar}">
            <div class="org-header">Switch organization</div>
            <div class="org-sub-header">
              Select the organization you want to switch to
            </div>
          </div>
          @if (isLoading) {
            <div class="mt-auto mb-auto">
              <ui-shimmers
                class="switch-org-icon-search-shimmer"
                [height]="36"
                [width]="38">
              </ui-shimmers>
            </div>
          }
          @if (!isLoading) {
            <div class="top-container" [ngClass]="{'max-lg:tw-w-full' : showSearchBar}">
              <div class="search-box">
                <app-simple-search
                  [value]="searchText"
                  [debounce]="300"
                  (inputChange)="onInputChange($event)"
                  (searchBoxState)="onSearchBoxStateChange($event)"
                  [showSearchBar]="showSearchBar"
                  class="tw-w-full lg:tw-hidden"/>
                @if (showSearchBar) {
                  <div (click)="toggleSearchBar(false)" class="cancel">Cancel</div>
                }

                <app-simple-search
                  [value]="searchText"
                  [debounce]="300"
                  (inputChange)="onInputChange($event)"
                  [autoHideSearchBar]="false"
                  (searchBoxState)="onSearchBoxStateChange($event)"
                  [showSearchBar]="true"
                  class="max-lg:tw-hidden"/>
              </div>
            </div>
          }
        </div>
        <div class="tw-border-1-px tw-border-solid tw-border-border-tertiary tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-py-spacing-4">
        </div>
      </div>
      @if (!isLoading) {
        <div class="orgs" [ngClass]="{'tw-w-3/10' : this.filteredOrgs.length === 0, 'tw-w-4/10' : this.filteredOrgs.length > 0 }">
          <!--Primeng by default forces a max-height of 200px to p-listbox. To fix this, scrollHeight:auto is used - ref https://github.com/primefaces/primeng/issues/14496-->
          <p-listbox scrollHeight="auto" [options]="filteredOrgs" [styleClass]="'tw-border-0 tw-bg-white-smoke-2 switch-org'" (onClick)="switchToOrg($event.option, 'User switched')">
            <ng-template let-org pTemplate="item">
              <div class="org-info">
                <div class="org-currency-name-info">
                  <div class="currency-icon">
                    <span>{{ org.currency }}</span>
                  </div>
                  <div class="org-name-domain">
                    <h3 class="org-name">{{ org.name }}</h3>
                    <h4 class="org-domain">{{ org.domain }}</h4>
                  </div>
                  @if (org.id === primaryOrg.id) {
                    <div class="primary-text">Primary Org</div>
                  }
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="empty">
              <div class="empty-org-state">
                <app-svg-sprite icon="org-filter-zero-state" class="no-org-found-icon"></app-svg-sprite>
                <div class="no-result-found">Sorry, no results found!</div>
                <div class="tw-text-comet empty-message-1">We could not find what you were looking for</div>
                <div class="tw-text-comet empty-message-2">Kindly check the keywords again</div>
              </div>
            </ng-template>
          </p-listbox>
        </div>
      }
      @if (isLoading) {
        <div class="orgs-shimmer">
          <p-listbox [options]="orgs" styleClass="tw-border-0 tw-bg-white-smoke-2" scrollHeight="auto">
            <ng-template let-index="index" pTemplate="item">
              <div class="org-info">
                <div class="org-currency-name-info">
                  <div class="currency-icon-shimmer">
                    <ui-shimmers
                      [width]="46"
                      [height]="46">
                    </ui-shimmers>
                  </div>
                  <div class="org-name-domain">
                    <ui-shimmers
                      [height]="18"
                      [width]="200">
                    </ui-shimmers>
                    <ui-shimmers
                      [height]="18"
                      [width]="120">
                    </ui-shimmers>
                  </div>
                  @if (index === 0) {
                    <ui-shimmers
                      [height]="24"
                      [width]="88"
                      class="primary-text-shimmer">
                    </ui-shimmers>
                  }
                </div>
              </div>
            </ng-template>
          </p-listbox>
        </div>
      }
    </div>
  </div>
}
<app-toast-message></app-toast-message>

export const environment = {
    production: true,
    GOOGLE_CLIENT_ID: '62198175306-h477rqb0lmglnlolib9pn2m9pjudrm2h.apps.googleusercontent.com',
    ROOT_URL: 'https://us1.fylehq.com',
    APP_URL: 'https://app.fylehq.com/app',
    APP_ROUTER_URL: 'https://accounts.fylehq.com',
    ROUTER_URL: 'https://accounts.fylehq.com',
    SENTRY_DSN: 'https://4a085c2fd6d64406821ecc300cc7e42b@o341960.ingest.sentry.io/3554020',
    SEND_ERROR_TO_SENTRY: true,
    SENTRY_TRACES_SAMPLE_RATE: 0.1,
    ENVIRONMENT: 'prod',
    RELEASE: 'af0c578cdd1d8fee31e20de766df3cb74c6bf4de',
    TARGET: 'fyle',
    GOOGLE_MAPS_API_KEY: 'AIzaSyDf-vdrlLzxZsgGqWUd_yiOXCJ6ToB4l9w',
    DWOLLA_ENVIRONMENT: 'prod',
    LAUNCH_DARKLY_CLIENT_ID: '61fb848da00c1016c73fbe55',
    NETLIFY_FYLE_APP_KEY: 'b148528c-3116-44eb-bd04-3bb6e9bcb9ce',
    NETLIFY_FUNCTION_URL: 'https://fyle-app-prod.netlify.app/.netlify/functions',
    DEPLOY_LOGS_WEBHOOK_URL: 'https://hooks.slack.com/services/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',
    C1_DEPLOY_LOGS_WEBHOOK_URL: 'https://hooks.slack.com/services/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',
    SLACK_COPILOT_FEEDBACK_WEBHOOK: 'https://hooks.slack.com/services/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',
    NETLIFY_ACCESS_TOKEN: 'lGbKhYoWjUZqXtRsietrw4vIS27AMCI5C6umcrxZQbQ',
    MIXPANEL_PROJECT_TOKEN: '56c5f4864cd63c40ee61dc476457f693',
    MIXPANEL_PROXY_URL: '',
    USE_MIXPANEL_PROXY: 'true',
    ENABLE_MIXPANEL: 'true',
    YODLEE_FAST_LINK_URL: 'https://fl4.prod.yodlee.com/authenticate/production-463/fastlink/?channelAppName=tieredprod',
  };
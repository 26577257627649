<div class="password-rules-container">
  <div class="tw-flex tw-items-center">
    <div class="tw-flex tw-flex-col tw-gap-spacing-8">
      <span class="tw-font-500 tw-text-size-14 tw-mb-2-px">Password should contain:</span>
      @for (error of errorMapping; track error) {
        <div class="tw-flex tw-items-center tw-gap-spacing-6">
          @if (!passwordStrength || !passwordStrength[error.type]) {
            <app-svg-sprite [icon]="'check-circle-fill'" class="tw-fill-icon-success tw-h-18-px tw-w-18-px"></app-svg-sprite>
          } @else {
            <app-svg-sprite [icon]="'check-circle-outline'" class="tw-fill-icon-disable tw-h-18-px tw-w-18-px"></app-svg-sprite>
          }
          <span class="text tw-text-size-14">{{ error.message }}</span>
        </div>
      }
    </div>
  </div>
</div>

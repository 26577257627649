<div class="auth-container" [ngClass]="{'!tw-bg-white tw-justify-center' : showOverlay}">

  @if (showOverlay) {
    <ui-spinner-loader [styleClass]="'spinner-50'">
      <span class="tw-mt-spacing-8 tw-text-text-primary tw-leading-1.4">Checking Google credentials</span>
    </ui-spinner-loader>
  } @else {

    <div class="card-container">
      <app-header></app-header>
      <div class="auth-card tw-mb-0">
        @if (errorState) {
          <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
            <app-svg-sprite
              class="icon-bg tw-flex tw-items-center tw-justify-center"
              icon="danger-outline"
              classes="tw-w-16-px tw-h-16-px tw-fill-fire-engine-red">
            </app-svg-sprite>
            <h3 class="header">{{header}}</h3>
            <div class="description">
              @for (description of descriptions; track description) {
                <div>{{ description }}</div>
              }
            </div>
            <!-- Don't show prompt to signup for demo if a new user is trying to sign up from a non-fyle env -->
            <div class="action-alternate">
              <div class="alternate-header">{{ alternateHeader }}</div>
              @if (!isNotAnExistingEmail) {
                <div class="alternate-description">
                  <span class="action" (click)="openSignInPage()">Sign in</span> using another account
                </div>
              }
              @if (isNotAnExistingEmail) {
                <div class="alternate-description">
                  <span class="action" (click)="openSignupDemoPage()">Sign up</span> for a demo
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  }

  @if (errorState) {
    <footer class="footer text-center">
      Never lose track
    </footer>
  }

</div>

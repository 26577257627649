// TODO: Aniruddha to fix the types here for get and set methods
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';

@Injectable({
  providedIn: 'root',
})
export class UserStorageService {
  constructor(private localStorageService: LocalStorageService) {}

  get<T>(key: string): T {
    return this.localStorageService.get(key);
  }

  set<T>(key: string, value: T): void {
    this.localStorageService.set(key, value);
  }

  remove(key: string): void {
    this.localStorageService.remove(key);
  }

  removeAll(): void {
    this.localStorageService.clear();
  }

  removeKeysOnLogout(): void {
    const userLevelLocalStorageKeys = ['cluster_domain', 'last_logged_in_org_queue'];
    for (const key of userLevelLocalStorageKeys) {
      this.localStorageService.remove(key);
    }
  }

  updateLastLoggedInOrgQueue(currentOrg: { id: string; name: string }, updatedOrg: { id: string; name: string }): void {
    const lastLoggedInOrgQueue = (this.get('last_logged_in_org_queue') as { id: string; name: string }[]) || [];

    const updatedQueue = lastLoggedInOrgQueue.map((org) =>
      org.id === currentOrg.id ? { ...org, name: updatedOrg.name } : org,
    );

    this.set('last_logged_in_org_queue', updatedQueue);
  }
}

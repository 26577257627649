<div class="auth-container">

  <div class="card-container">
    <app-header></app-header>
    <div class="auth-card">

      <div class="icon-container">
        @if (isVerificationEmailSent) {
          <div class="tw-h-40-px tw-w-40-px tw-pt-4-px tw-flex tw-justify-center tw-items-center tw-bg-bg-success-light tw-rounded">
            <app-svg-sprite icon="check-circle-fill" classes="tw-fill-icon-success tw-h-20-px tw-w-20-px"></app-svg-sprite>
          </div>
        }  @else if (hasTokenExpired) {
          <div class="tw-h-40-px tw-w-40-px tw-pt-4-px tw-flex tw-justify-center tw-items-center tw-bg-bg-danger-lighter tw-rounded">
            <app-svg-sprite icon="danger-outline" classes="tw-fill-icon-danger tw-h-20-px tw-w-20-px"></app-svg-sprite>
          </div>
        } @else {
          <div class="tw-h-40-px tw-w-40-px tw-pt-4-px tw-flex tw-justify-center tw-items-center tw-bg-bg-disable-lighter tw-rounded">
            <app-svg-sprite icon="send-outline" classes="tw-fill-icon-primary tw-h-20-px tw-w-20-px"></app-svg-sprite>
          </div>
        }
      </div>

      @if (!isVerificationEmailSent) {
        <div class="content-container">
          <h3 class="heading tw-text-text-primary tw-text-20-px tw-leading-7 tw-m-0 tw-mb-8-px">{{ title }}</h3>
          <p class="description-2 tw-text-text-muted tw-leading-1.5 tw-m-0 tw-mb-24-px">
            Please enter your registered email address to receive a new invitation to set up your account on {{ targetConfig.BRAND_NAME }}.
          </p>

          @if (!email) {
            <form class="form-container" novalidate>
              <label for="email" class="tw-text-start tw-text-form-label-text-color tw-leading-5 tw-mb-8-px">Registered email</label>
              <div class="email-input-container tw-relative">
                <input
                  id="email"
                  [formControl]="emailForm"
                  autocomplete="off"
                  type="email"
                  placeholder="Enter registered email"
                  class="input-text input-content"
                  [ngClass]="{'p-error' : emailForm.touched && emailForm.invalid}"
                  appAutofocus>
                <div class="error-space">
                </div>
                @if (emailForm.touched && emailForm.invalid) {
                  <div class="error tw-flex tw-gap-spacing-4 tw-text-size-12">
                    <app-svg-sprite icon="danger-fill" classes="tw-h-14-px tw-w-14-px tw-fill-form-error-icon-color tw-mt-2-px"></app-svg-sprite>
                    @if (emailForm.hasError('required')) {
                      <span class="error-message">Enter an email address</span>
                    } @else if (emailForm.hasError('email')) {
                      <span class="error-message">Enter a valid email address</span>
                    }
                  </div>
                }
                @if (emailForm.value && emailForm.value.match(emailPattern)) {
                  <div class="tw-absolute tw-top-16-px tw-right-16-px tw-cursor-pointer">
                    <app-svg-sprite icon="check-circle-fill" classes="tw-fill-icon-success tw-w-18-px"></app-svg-sprite>
                  </div>
                }
              </div>
              <ui-button
                [customClasses]="'tw-w-full button-medium tw-mt-12-px'"
                [defaultType]="'submit'"
                [buttonType]="buttonType.PRIMARY"
                (buttonClick)="resendVerification()"
                [isLoading]="showLoaderOnButton"
                [loadingText]="'Resending'"
                [label]="'Send invite'"
                [validateForm]="true"
                >
              </ui-button>
            </form>
          }
        </div>
      }

      @if (isVerificationEmailSent) {
        <div class="tw-text-center">
          <h3 class="title">Invitation link sent</h3>
          <p class="description-3 tw-text-text-label tw-leading-1.5">
            A new invitation link has been sent to your registered email address. Please check your inbox to continue setting up your account.
          </p>
        </div>
      }
    </div>

    <div class="footer">
      <span>NEVER LOSE TRACK</span>
    </div>

  </div>
</div>

<app-toast-message></app-toast-message>
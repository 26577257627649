import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'ui-spinner-loader',
  standalone: true,
  imports: [CommonModule, ProgressSpinnerModule],
  templateUrl: './ui-spinner-loader.component.html',
  styleUrl: './ui-spinner-loader.component.scss',
})
export class UiSpinnerLoaderComponent {
  @Input() styleClass: string;
}

import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterAuthService } from '@fyle/router-auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@fyle/auth';
import { catchError, EMPTY, finalize, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { ExtendedOrgUser } from '@fyle/model-shared';
import { HttpErrorResponse } from '@angular/common/http';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SvgSpriteComponent } from '@fyle/svg-sprite';
import { TargetAppConfigService } from '@fyle/target-app-config';

@Component({
  selector: 'feature-magic-link-signin',
  standalone: true,
  imports: [CommonModule, SvgSpriteComponent, ProgressSpinnerModule],
  templateUrl: './feature-magic-link-signin.component.html',
  styleUrl: './feature-magic-link-signin.component.scss',
})
export class FeatureMagicLinkSigninComponent implements OnInit, OnDestroy {
  isAPIInProgress = signal(false);

  headerText = signal('');

  descriptionText = signal('');

  iconUrl = signal('');

  private routerAuthService = inject(RouterAuthService);

  private activatedRoute = inject(ActivatedRoute);

  private authService = inject(AuthService);

  private targetAppConfigService = inject(TargetAppConfigService);

  private onDestroy$ = new Subject<void>();

  private router = inject(Router);

  private setExtendedOrgUser(): Observable<ExtendedOrgUser> {
    let eou$: Observable<ExtendedOrgUser>;
    const eou = this.authService.getEou();
    if (eou) {
      eou$ = of(eou);
    } else {
      eou$ = this.authService.refreshEou();
    }
    return eou$;
  }

  private handleError(error: HttpErrorResponse): void {
    switch (error.status) {
      case 400:
        this.router.navigate(['pending_verification']);
        break;

      case 422:
        this.router.navigate(['disabled']);
        break;

      case 401:
        this.descriptionText.set('Your account will be temporarily locked after 5 unsuccessful login attempts.');
        break;

      case 433:
        this.headerText.set('Try logging in after 5 minutes');
        this.descriptionText.set('This account is temporarily locked due to multiple unsuccessful login attempts.');
        break;

      default:
        this.headerText.set('Something went wrong');
        this.descriptionText.set('Please try again later.');
    }
  }

  private handleMagicLink(): void {
    this.isAPIInProgress.set(true);
    const token = this.activatedRoute.snapshot.queryParams.token;
    this.routerAuthService
      .handleMagicLinkToken(token)
      .pipe(
        switchMap(() => this.setExtendedOrgUser()),
        catchError((error: HttpErrorResponse) => {
          this.handleError(error);
          return EMPTY;
        }),
        finalize(() => this.isAPIInProgress.set(false)),
        takeUntil(this.onDestroy$),
      )
      .subscribe(() => {
        this.router.navigate(['switch_org']);
      });
  }

  /**
   * Clear local storage on magic link login to prevent retaining previous account data.
   * Example:
   * - User logs in to Account A via magic link.
   * - Without logging out, logs in to Account B via another magic link in the same/new tab.
   * - Account A's data remains in local storage, and when a new tab checks login status,
   *  it finds Account A's data instead of Account B's, causing incorrect login status.
   * Clearing local storage ensures only the active account data is stored.
   */
  private logoutExistingUser(): void {
    this.authService.logout().pipe(takeUntil(this.onDestroy$)).subscribe();
  }

  ngOnInit(): void {
    const targetConfig = this.targetAppConfigService.getTargetConfig();
    this.iconUrl.set(targetConfig.FULL_LOGO_URL_BLACK);
    this.logoutExistingUser();
    this.handleMagicLink();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}

<div class="auth-container new-password-container">

  <div class="card-container">

    <app-header></app-header>

    <div class="auth-card tw-gap-spacing-16">

      <h3 class="title">Reset your password</h3>

      @if (isResetPasswordFailed) {
        <div class="error-message-box">
          This link has expired. Please use the <a class="reset-password" (click)="goToResetPassword()">reset password page</a> to request for a new link.
        </div>
      }

      <form class="form-container" #passwordFormElement [formGroup]="passwordForm">

        <div class="new-password-container">

          <div class="password-label tw-flex tw-items-end tw-justify-between">
            <div class="tw-flex tw-flex-row">
              <label class="input-label">Enter new password</label>
              <span class="asterisk">*</span>
            </div>
          </div>

          <div class="password-input tw-relative">
            <input
              formControlName="newPassword"
              autocomplete="current-password"
              type="{{showPlainPassword ? 'text' : 'password'}}"
              [ngClass]="{'p-error' : passwordForm.controls.newPassword.touched && passwordForm.controls.newPassword.invalid}"
              placeholder="Enter your new password here"
              class="input-content input-text"
              [fdlTooltip]="passwordRequirements"
              [tooltipShowEvent]="'focus'"
              [tooltipDirection]="'right'"
              [tooltipCustomStyleClass]="'password-requirements-tooltip'"
              [tooltipEscape]="true"
              >
            <div class="error-space">
            </div>

            <ng-template #passwordRequirements>
              <app-password-rules [passwordStrength]="passwordForm.controls.newPassword.errors"></app-password-rules>
            </ng-template>

            @if (!showPlainPassword) {
              <div class="action-wrapper" (click)="showPlainPassword = !showPlainPassword">
                <span class="action-text">
                  <app-svg-sprite [icon]="'eye-slash'" class="icon common-icon"></app-svg-sprite>
                </span>
              </div>
            }
            @if (showPlainPassword) {
              <div class="action-wrapper" (click)="showPlainPassword = !showPlainPassword">
                <span class="action-text">
                  <app-svg-sprite [icon]="'eye'" class="icon common-icon"></app-svg-sprite>
                </span>
              </div>
            }

            @if (passwordForm.controls.newPassword.touched && passwordForm.controls.newPassword.errors) {
              @if (passwordForm.controls.newPassword.hasError('required')) {
                <div class="error">Please enter your password</div>
              }
            }

          </div>

        </div>

        <div class="confirm-password-container">

          <div class="password-label tw-flex tw-items-end tw-justify-between">
            <div class="tw-flex tw-flex-row">
              <label class="input-label">Re-enter new password</label>
              <span class="asterisk">*</span>
            </div>
          </div>

          <div class="password-input tw-relative">
            <input
              formControlName="confirmPassword"
              autocomplete="confirm-password"
              type="{{showPlainConfirmPassword ? 'text' : 'password'}}"
              [ngClass]="{'p-error' : passwordForm.controls.confirmPassword.touched && passwordForm.controls.confirmPassword.invalid}"
              placeholder="Re-enter your new password here"
              class="input-content input-text"
              >
            <div class="error-space">
            </div>

            @if (!showPlainConfirmPassword) {
              <div class="action-wrapper" (click)="showPlainConfirmPassword = !showPlainConfirmPassword">
                <span class="action-text">
                  <app-svg-sprite [icon]="'eye-slash'" class="icon common-icon"></app-svg-sprite>
                </span>
              </div>
            }
            @if (showPlainConfirmPassword) {
              <div class="action-wrapper" (click)="showPlainConfirmPassword = !showPlainConfirmPassword">
                <span class="action-text">
                  <app-svg-sprite [icon]="'eye'" class="icon common-icon"></app-svg-sprite>
                </span>
              </div>
            }

            @if (passwordForm.controls.confirmPassword.touched && passwordForm.hasError('mismatch')) {
              <div class="action-wrapper tw-mr-32-px">
                <span class="action-text">
                  <app-svg-sprite [icon]="'danger-fill'" class="error-icon common-icon"></app-svg-sprite>
                </span>
              </div>
            }

            @if (passwordForm.controls.confirmPassword.touched && passwordForm.controls.confirmPassword.errors) {
              @if (passwordForm.controls.confirmPassword.hasError('required') && !passwordForm.hasError('mismatch')) {
                <div class="error">Please re-enter your password</div>
              }
            }

            @if (passwordForm.controls.confirmPassword.touched && passwordForm.hasError('mismatch')) {
              <div class="error">The re-entered password does not match</div>
            }

          </div>
        </div>

        <ui-button
          [validateForm]="true"
          [isLoading]="showLoaderOnButton"
          [loadingText]="'Resetting'"
          [formToValidate]="passwordForm"
          [formElement]="passwordFormElement"
          [customClasses]="'tw-font-700 tw-w-full tw-mt-spacing-4'"
          [defaultType]="'submit'"
          [buttonType]="buttonType.PRIMARY"
          (buttonClick)="savePassword()"
          [label]="'Reset password'"
          >
        </ui-button>

      </form>

      <div class="alternate-text" (click)="goToSignIn()">
        <p>&lt;</p>
        <div>Know your password? Sign in</div>
      </div>

    </div>
  </div>
  <footer class="footer">Never lose track</footer>
</div>

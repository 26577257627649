import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DebouncedFunc } from 'lodash-es/debounce';
import debounce from 'lodash-es/debounce';
import { noop } from 'rxjs';

@Component({
  selector: 'app-simple-search',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./simple-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SimpleSearchComponent,
      multi: true,
    },
  ],
})
export class SimpleSearchComponent implements OnInit, ControlValueAccessor {
  @ViewChild('searchBox') searchBox: ElementRef;

  @Input() showSearchBar: boolean;

  @Input() autoHideSearchBar: boolean = true;

  @Input() debounce: number;

  @Input() placeholder = 'Search';

  @Input() value: any;

  @Output() searchBoxState: EventEmitter<'open' | 'close'> = new EventEmitter<'open' | 'close'>();

  @Output() focused: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();

  @Output() blured: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();

  @Output() inputChange: EventEmitter<any> = new EventEmitter<any>();

  disabled = false;

  private debounceInputFunc: DebouncedFunc<any>;

  private onModelTouched: () => void = noop;

  private onModelChange: (_: any) => void = noop;

  private touched = false;

  constructor() {}

  private setFocus() {
    this.searchBox.nativeElement.focus();
  }

  ngOnInit() {
    if (this.debounce) {
      this.debounceInputFunc = debounce(() => {
        this.onModelChange(this.value);
        this.inputChange.emit(this.value);
      }, this.debounce);
    }
  }

  clearInput() {
    this.value = '';
    this.setFocus();
    this.onModelChange(this.value);
    this.inputChange.emit(this.value);
  }

  writeValue(obj: any) {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onModelTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onFocus(event: FocusEvent) {
    this.focused.emit(event);
  }

  onBlur(event: FocusEvent) {
    if (!this.touched) {
      this.onModelTouched();
      this.touched = true;
    }
    this.blured.emit(event);
    if (this.autoHideSearchBar) {
      this.showSearchBarFn(false);
    }
  }

  onInputChange(event: Event) {
    if (this.debounceInputFunc) {
      this.debounceInputFunc();
    } else {
      this.onModelChange(this.value);
      this.inputChange.emit(this.value);
    }
  }

  showSearchBarFn(showSearchBar: boolean) {
    if (!showSearchBar && !this.value) {
      this.showSearchBar = false;
    } else if (!showSearchBar && this.value) {
      this.showSearchBar = true;
    } else if (showSearchBar) {
      this.showSearchBar = true;
    }
    if (this.showSearchBar) {
      this.setFocus();
    }
    this.searchBoxState.emit(this.showSearchBar ? 'open' : 'close');
  }
}
